$andes-theme: 'mercadopago';

@import '~@andes/common/index';
@import '~@andes/typography/index';
@import '~@andes/card/index';
@import '~@andes/amount-field/index';
@import '~@andes/button/index';
@import '~@andes/thumbnail/index';
@import '~@andes/common/lib/styles/visually-hidden';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.andes-card {
  &__header {
    margin: 0 $andes-spacing-24;
  }
}

.calculator-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  background-color: $andes-white;
  padding-top: 0;

  .card-calculator {
    width: 100vw;
    height: 377px;

    h2 {
      text-align: center;
    }

    &__header {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 1px solid $andes-gray-100;

      &-desc {
        margin-left: 10px;
      }

      &-url {
        color: lighten($andes-black, 60%);
        font-size: 0.75rem;
        line-height: 18px;
      }

      .andes-thumbnail-container {
        padding-right: 16px;
      }

      @media (width >= 768px) {
        border-bottom: none;
      }
    }

    &__body {
      padding: $andes-spacing-32;

      .andes-amount-field__main-container {
        padding: 50px;
      }
    }

    &__footer {
      text-align: center;
      padding-bottom: 42px;
      border-bottom: 1px solid $andes-gray-100;

      button {
        width: 312px;
      }

      @media (width >= 768px) {
        border-bottom: none;
      }
    }

    &__security-message {
      margin-top: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: lighten($andes-black, 45%);
      font-size: 0.75rem;
      line-height: 16px;
    }

    @media (width >= 580px) {
      width: 580px;
    }
  }

  @media (width >= 768px) {
    background-color: $andes-bg-secondary;
    padding-top: $andes-spacing-64;
  }
}
